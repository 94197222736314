//export type MessageTypeSuccess = 'success'
//export type MessageTypeError = 'error'
//export type MessageType = MessageTypeSuccess | MessageTypeError

export const authorityLevel = {
    systemAdmin: 10,
    operationAdmin: 20,
    companyOwner: 30,
    brandManager: 40,
    facilityManager: 50
}