
import { defineComponent, reactive, ref, onMounted, watch, computed } from 'vue'
import { authorityLevel as authorityLevelConst } from '@/modules/constants'
import User from '@/models/user'
import Company from '@/models/company'
import Brand from '@/models/brand'
import Facility from '@/models/facility'
import Role from '@/models/role'
import http from "@/modules/httpclient"
import { ElNotification } from 'element-plus'

//type MessageType = 'success' | 'error'

export default defineComponent({
  props:{
      loginUser:User
  },
  setup() {
    const searchCondition = reactive<User>(new User('', '', '', '', '', '', 0, ''))
    const users = reactive<User[]>([])
    const user = reactive<User>(new User('', '','', '', '', '', 0, ''))
    const companies = reactive<Company[]>([])
    const brands = reactive<Brand[]>([])
    const filteredBrands = reactive<Brand[]>([])
    const facilities = reactive<Facility[]>([])
    const filteredFacilities = reactive<Facility[]>([])
    const roles = reactive<Role[]>([])

    const companySelect = reactive({
      multiple:false,
      disabled:false,
    })

    const brandSelect = reactive({
      multiple:true, // 基本的にtrueのはず
      disabled:false
    })

    const facilitySelect = reactive({
      multiple:true, // 基本的にtrueのはず
      disabled:false
    })
    
    const isEdit = ref(false)
    const isDeleteVisible = ref(false)
    const isDialogVisible = ref(false)
    const isConfirmVisible = ref(false)
    const isSearchVisible = ref(false)
    const form = ref()
    const filter = ref('')

    const dispColumns = reactive({
      email:true,
      displayName:true,
      authorityRole:true,
      companyName:true,
      brandName:true,
      facilityName:true,
    })

    const clearValidate = () => {
        form.value.clearValidate()
    }

    // 入力チェック
    const rules = {
      email: [
        {
          required: true,
          message: '必須入力です',
          trigger: 'blur'
        },
        {
          type: 'email',
          message: 'メールアドレスを入力してください',
          trigger: 'blur'
        },
        {
          validator: async (rule, value, callback) => {
              if(isEdit.value){
                  // 編集時はスルー
                  callback()
              }

              try{
                  const res = await http.get('/v1/users',{
                      params:{
                          email:value,
                          isDeleted:1 // 削除済みも含む
                      }
                  })
                  if(res.data.users.length > 0){
                      callback(new Error('すでに登録されています'))
                  }
                  else{
                      // OK
                      callback()
                  }
              }
              catch (reason){
                  ElNotification({ type: 'error', message: '予期しないエラーが発生しました' })
                  callback(new Error('予期しないエラーが発生しました'))
              }
          },
          trigger: 'blur'
        }
      ],
      displayName: [
        {
          required: true,
          message: '必須入力です',
          trigger: 'blur'
        }
      ],
      authorityRole: [
        {
          required: true,
          message: '選択必須です',
          trigger: 'blur'
        }
      ],
      companies: [
        {
          validator(rule, value, callback){
            // システム管理者以外は必須
            if (authorityLevel.value >= authorityLevelConst.operationAdmin && (value === "" || value.length === 0)){
              callback(new Error('選択必須です'))
            }
            callback()
          },
          trigger: 'blur'
        }
      ],
      brands: [
        {
          validator(rule, value, callback){
            // ブランド担当者以下は必須
            if(authorityLevel.value >= authorityLevelConst.brandManager && (value === "" || value.length === 0)){
              callback(new Error('選択必須です'))
            }
            callback()
          },
          trigger: 'blur'
        }
      ],
      facilities: [
        {
          validator(rule, value, callback){
            if(authorityLevel.value >= authorityLevelConst.facilityManager && (value === "" || value.length === 0)){
              callback(new Error('選択必須です'))
            }
            callback()
          },
          trigger: 'blur'
        }
      ]
    }


    // 検索
    const handleSearch = async () => {
      try {
        const res = await http.get('/v1/users', { params: {
          email: searchCondition.email,
          displayName: searchCondition.displayName,
          authorityRole: searchCondition.authorityRole,
          isDeleted: searchCondition.isDeleted ? 1 : 0
        }})
        users.splice(0)
        res.data.users.map((u: any) => {
          users.push(new User(u.email, u.authorityRole, u.displayName, u.companies, u.brands, u.facilities,  u.isDeleted, 
            u.authorityRoleName, u.companyNames, u.brandNames, u.facilityNames))
        })
        if (users.length > 0) {
          ElNotification({ type: 'success', message: `${users.length} 件ヒットしました` })
        } else {
            ElNotification({ type: 'error', message: '検索結果がありません' })
        }
      }
      catch (reason:any) {
        ElNotification({ type: 'error', message: '処理に失敗しました' })
        throw new Error(`search error: ${reason.response.status} ${reason.response.data.message}`)
      }
    }

    // 新規作成ダイアログ表示
    const showCreateDialog = () => {
      user.email = ''
      user.authorityRole = ''
      user.displayName = ''
      user.isDeleted = false
      user.companies = ''
      user.brands = ''
      user.facilities = ''

      isEdit.value = false
      isDeleteVisible.value = false
      isDialogVisible.value = true
    }

    // 新規作成
    const handleCreate = () => {
      
      // 入力チェック
      form.value.validate(async (valid) => { 
        if (!valid) {
          // validation error
          return
        }
        try{
          const res = await http.post('/v1/users', { 
            email: user.email,
            displayName: user.displayName,
            authorityRole: user.authorityRole,
            authorityLevel: authorityLevel.value,
            companies: typeof user.companies === 'string' ? [user.companies] : user.companies,
            brands: typeof user.brands === 'string' ? [user.brands] : user.brands,
            facilities: typeof user.facilities === 'string' ? [user.facilities] : user.facilities,
            
            isDeleted: user.isDeleted ? 1 : 0,
          })
          // 加盟店名
          let companyNames
          if(user.companies === '' || user.companies.length === 0){
            companyNames = ['all'] 
          }
          else if(typeof user.companies === 'string'){
            companyNames = [companies[companies.findIndex(i=> i.companyID === user.companies)].companyName]
          }
          else{
            companyNames = user.companies.map(c => companies[companies.findIndex(i=>i.companyID === c)].companyName)
          }
          // ブランド名
          let brandNames
          if(user.brands === '' || user.brands.length === 0){
            brandNames = ['all']
          }
          else if(typeof user.brands === 'string'){
            brandNames = [brands[brands.findIndex(i => i.brandID === user.brands)].brandName]
          }
          else{
            brandNames = user.brands.map(b=>brands[brands.findIndex(i=>i.brandID === b)].brandName)
          }
          // 施設名
          let facilityNames
          if(user.facilities === '' || user.facilities.length === 0){
            facilityNames = ['all']
          }
          else if(typeof user.facilities === 'string'){
            facilityNames = [facilities[facilities.findIndex(i => i.facilityID === user.facilities)].facilityName]
          }
          else{
            facilityNames = user.facilities.map(f=>facilities[facilities.findIndex(i=>i.facilityID === f)].facilityName)
          }
          users.push(new User(user.email, user.authorityRole, user.displayName, user.companies, user.brands, user.facilities, user.isDeleted ? 1 : 0,
            roles[roles.findIndex((r)=> r.roleCode === user.authorityRole)].roleName,
            companyNames, brandNames, facilityNames))
          ElNotification({ type: 'success', message: '正常終了しました' })
          isDialogVisible.value = false
        }
        catch (reason: any) {
          ElNotification({ type: 'error', message: '処理に失敗しました' })
          isDialogVisible.value = false
          throw new Error(`create user error: ${reason.response.status} ${reason.response.data.message}`)
        }
      })

    }

    // 更新ダイアログ表示
    const showUpdateDialog = (idx: number, row: User) => {
      user.email = row.email
      user.authorityRole = row.authorityRole
      user.displayName = row.displayName
      user.companies = row.companies
      user.brands = row.brands
      user.facilities = row.facilities
      user.isDeleted = row.isDeleted

      isEdit.value = true
      isDeleteVisible.value = row.isDeleted
      isDialogVisible.value = true
    }

    // 更新
    const handleUpdate = () => {

      // 入力チェック
      form.value.validate(async (valid) => {
        if (!valid) {
          // validation error
          return
        }

        try {
          const res = await http.patch(`/v1/users`, { 
            email: user.email,
            displayName: user.displayName,
            authorityRole: user.authorityRole,
            authorityLevel: authorityLevel.value,
            companies: typeof user.companies === 'string' ? [user.companies] : user.companies,
            brands: typeof user.brands === 'string' ? [user.brands] : user.brands,
            facilities: typeof user.facilities === 'string' ? [user.facilities] : user.facilities,
            
            isDeleted: user.isDeleted ? 1 : 0,
          })

          const u = users[users.findIndex(i => i.email === user.email)]
            u.email = user.email
            u.displayName = user.displayName
            u.authorityRole = user.authorityRole
            u.authorityRoleName = roles[roles.findIndex(i=> i.roleCode === user.authorityRole)].roleName
            u.isDeleted = user.isDeleted
            u.companies = user.companies
            // 加盟店名
            if(user.companies === '' || user.companies.length === 0){
              u.companyNames = 'all'
            }
            else if(typeof user.companies === 'string'){
              u.companyNames = companies[companies.findIndex(i=> i.companyID === user.companies)].companyName
            }
            else{
              u.companyNames = user.companies.map(c => companies[companies.findIndex(i=>i.companyID === c)].companyName).join(',')
            }
            // ブランド名
            u.brands = user.brands
            if(user.brands === '' || user.brands.length === 0){
              u.brandNames = 'all'
            }
            else if(typeof user.brands === 'string'){
              u.brandNames = brands[brands.findIndex(i => i.brandID === user.brands)].brandName
            }
            else{
              u.brandNames = user.brands.map(b=>brands[brands.findIndex(i=>i.brandID === b)].brandName).join(',')
            }
            // 施設名
            u.facilities = user.facilities
            if(user.facilities === '' || user.facilities.length === 0){
              u.facilityNames = 'all'
            }
            else if(typeof user.facilities === 'string'){
              u.facilityNames = facilities[facilities.findIndex(i => i.facilityID === user.facilities)].facilityName
            }
            else{
              u.facilityNames = user.facilities.map(f=>facilities[facilities.findIndex(i=>i.facilityID === f)].facilityName).join(',')
            }

          ElNotification({ type: 'success', message: '正常終了しました' })
          isDialogVisible.value = false
        }
        catch (reason: any){
          ElNotification({ type: 'error', message: '処理に失敗しました' })
          isDialogVisible.value = false
          throw new Error(`update user error: ${reason.response.status} ${reason.response.data.message}`)
        }
      })
    }

    // 削除確認ダイアログ表示
    const showDeleteDialog = (idx: number, row: User) => {
      user.email = row.email
      user.authorityRole = row.authorityRole
      user.displayName = row.displayName

      isConfirmVisible.value = true
    }

    // 削除
    const handleDelete = async () => {
      try {
        const res = await http.patch(`/v1/users/delete`,{
          email: user.email,
          authorityRole: user.authorityRole,
          displayName: user.displayName
        })
        users.splice(users.findIndex(i => i.email === user.email), 1)
        ElNotification({ type: 'success', message: '正常終了しました' })
        isConfirmVisible.value = false
      }
      catch (reason: any){
        ElNotification({ type: 'error', message: '処理に失敗しました' })
        isConfirmVisible.value = false
        throw new Error(`delete user error: ${reason.response.status} ${reason.response.data.message}`)
      }
      
    }

    const getCompanies = async () => {
        try {
            const res = await http.get('/v1/companies')
            companies.splice(0)
            res.data.companies.map((c: any) =>  {
                companies.push(new Company(c.companyID, c.companyName, c.companyAlias, c.businessCode, c.isDeleted))
            })
        }
        catch (reason){
            ElNotification({ type: 'error', message: '加盟店マスタ取得処理に失敗しました' })
        }
    }

    const getBrands = async () =>{
      try {
        const res = await http.get('/v1/brands')
        brands.splice(0)
        res.data.brands.map((b: any) => {
          brands.push(new Brand(b.brandID, b.brandName, b.brandCode, b.companyID, b.timeZone, b.isDeleted, b.companyName))
        })
      }
      catch (reason) {
        ElNotification({ type: 'error', message: 'ブランドマスタ取得処理に失敗しました' })
      }
    }

    const filterBrands = () =>{
      filteredBrands.splice(0)
      filteredBrands.push(...brands.filter((brand)=>{
        if(typeof user.companies === 'string'){
          return user.companies === brand.companyID
        }
        else{
          return user.companies.includes(brand.companyID)
        }
      }))
    }

    const getFacilities = async () => {
      try{
        const res = await http.get('/v1/facilities')
        facilities.splice(0)
        res.data.facilities.map((f: any) => {
          facilities.push(new Facility(f.facilityID, f.facilityName, f.facilityCode, f.brandID, 
                        null, null, null, null, null, null, null, null, null, null, f.isDeleted, f.brandName))
        })
      }
      catch (reason) {
        ElNotification({ type: 'error', message: '施設マスタ取得処理に失敗しました' })
      }
    }

    const filterFacility = () => {
      filteredFacilities.splice(0)
      filteredFacilities.push(...facilities.filter((facility)=>{
        return user.brands.includes(facility.brandID)
      }))
    }

    const getRoles = async () => {
      try {
        const res = await http.get('/v1/roles')
        res.data.roles.map((r: any) => {
          roles.push(new Role(r.roleCode, r.roleName, r.authorityLevel, r.remarks, [],r.isDeleted))
        })
      }
      catch (reason){
        ElNotification({ type: 'error', message: '権限マスタ取得処理に失敗しました' })
      }
    }

    const authorityLevel = computed( () => {
        const index = roles.findIndex(i => i.roleCode === user.authorityRole)
        return index >= 0 ? roles[index].authorityLevel : 99
      })

    watch(()=>user.authorityRole, (roleCode,prevCode)=>{
      // 空なら更新ダイアログ立ち上げ時に値を入れた場合なので、初期化しない
      if(prevCode !== ''){
        // 編集中の変更なのでクリアする
        user.companies = ''
        user.brands = []
        user.facilities = []
      }

      if(authorityLevel.value <= authorityLevelConst.systemAdmin){
        // システム管理者
        // 値登録不要
        companySelect.multiple = false
        companySelect.disabled = true
        // brandSelect.multiple = false
        brandSelect.disabled = true
        // facilitySelect.multiple = false
        facilitySelect.disabled = true
      }
      else if(authorityLevel.value <= authorityLevelConst.operationAdmin){
        // 運用管理者
        // 加盟店を複数選択可能
        if(user.companies !== '' && typeof user.companies == 'string'){
          user.companies = [user.companies]
        }
        companySelect.multiple = true
        companySelect.disabled = false
        // brandSelect.multiple = false
        brandSelect.disabled = true
        // facilitySelect.multiple = false
        facilitySelect.disabled = true
      }
      else if(authorityLevel.value <= authorityLevelConst.companyOwner){
        // 加盟店オーナー
        // 加盟店（一つ）のみ登録
        companySelect.multiple = false
        companySelect.disabled = false
        // brandSelect.multiple = false
        brandSelect.disabled = true
        // facilitySelect.multiple = false
        facilitySelect.disabled = true
      }
      else if(authorityLevel.value <= authorityLevelConst.brandManager){
        // ブランド担当者
        // 施設は登録不要
        companySelect.multiple = false
        companySelect.disabled = false
        // brandSelect.multiple = true
        brandSelect.disabled = false
        // facilitySelect.multiple = false
        facilitySelect.disabled = true
      }
      else {
        // 施設担当者 or 利用者
        companySelect.multiple = false
        companySelect.disabled = false
        // brandSelect.multiple = true
        brandSelect.disabled = false
        // facilitySelect.multiple = true
        facilitySelect.disabled = false
      }
    })

    watch( () => user.companies,(companies,prevCompanies)=>{
      // 空なら更新ダイアログ立ち上げ時に値を入れた場合なので、初期化しない
      if(prevCompanies !== '' && prevCompanies.length > 0){
        // 編集中の変更なのでクリアする
        user.brands = []
      }
      
      if(authorityLevel.value  >= authorityLevelConst.brandManager){
        // ブランド担当者以下ならブランドを登録するので一覧を取得
        filterBrands()
      }
      else {
        filteredBrands.splice(0)
      }
    })

    watch(()=> user.brands, (brands, prevBrands)=>{
      // 空なら更新ダイアログ立ち上げ時に値を入れた場合なので、初期化しない
      if(prevBrands !== '' && prevBrands.length > 0){
        // 編集中の変更なのでクリアする
        user.facilities = []
      }

      if(authorityLevel.value >= authorityLevelConst.facilityManager){
        // 施設担当者以下なら施設登録をするので一覧を取得
        filterFacility()
      }
      else {
        filteredFacilities.splice(0)
      }
    })

    watch(()=>isDialogVisible.value,(visible,prev)=>{
        if(!visible){
            // HACK: 各IDが編集時にも初期化されてしまうので、前の値が空ならダイアログ立ち上げ時とみなし初期化しない
            // isDialogVisibleをwatchしてダイアログを閉じたときにIDを空にしておく。もっと良い方法はないか？
            user.authorityRole = ''
            user.companies = ''
            user.brands = []
            user.facilities = []

            companySelect.multiple = false
            companySelect.disabled = false
            // brandSelect.multiple = false
            brandSelect.disabled = false
            // facilitySelect.multiple = false
            facilitySelect.disabled = false
        }
    })


    onMounted(() => {
      getCompanies()
      getBrands()
      getFacilities()
      getRoles()
      handleSearch()
    })

    return { searchCondition, users, user, 
      companies, brands, filteredBrands, facilities, filteredFacilities, roles, authorityLevel,
      companySelect, brandSelect, facilitySelect,
      form, rules, clearValidate, filter,
      showCreateDialog, showUpdateDialog, showDeleteDialog,
      handleCreate, handleUpdate, handleSearch, handleDelete,
      isEdit, isDialogVisible, isConfirmVisible, isSearchVisible, isDeleteVisible,
      dispColumns }
  }

})

