export default class Role {
    roleCode: string
    roleName: string
    authorityLevel: number
    remarks: string
    functions: string[]
    isDeleted: boolean

    constructor(roleCode: string, roleName: string, authorityLevel: number, remarks: string, functions: string[], isDeleted: number) {
        this.roleCode = roleCode
        this.roleName = roleName
        this.authorityLevel = authorityLevel
        this.remarks = remarks
        this.functions = functions 
        this.isDeleted = isDeleted === 1
    }
}