
import { defineComponent, reactive, ref, onMounted, watch } from 'vue'
import Terminal from '@/models/terminal'
import Company from '@/models/company'
import Brand from '@/models/brand'
import Facility from '@/models/facility'
import Location from '@/models/location'
import User from '@/models/user'
import http from "@/modules/httpclient"
import { ElNotification } from 'element-plus'

export default defineComponent({
    props:{
      loginUser:User
    },
    setup() {
        const searchCondition = reactive<Terminal>(new Terminal('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 0))
        const terminals = reactive<Terminal[]>([])
        const terminal = reactive<Terminal>(new Terminal('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 0))
        const companies = reactive<Company[]>([])
        // const companiesSearch = reactive<Company[]>([])
        const brands = reactive<Brand[]>([])
        const filteredBrands = reactive<Brand[]>([])
        const filteredBrandsSearch = reactive<Brand[]>([])
        const facilities = reactive<Facility[]>([])
        const filteredFacilities = reactive<Facility[]>([])
        const filteredFacilitiesSearch = reactive<Facility[]>([])
        const locations = reactive<Location[]>([])
        const filteredLocations = reactive<Location[]>([])
        const filteredLocationsSearch = reactive<Location[]>([])

        const isEdit = ref(false)
        const isDeleteVisible = ref(false)
        const isDialogVisible = ref(false)
        const isConfirmVisible = ref(false)
        const isSearchVisible = ref(false)
        const form = ref()
        const filter = ref('')

        const dispColumns = reactive({
            terminalID: false,
            companyID: false,
            companyName: true,
            brandID: false,
            brandName: true,
            facilityID: false,
            facilityName: true,
            locationID: false,
            locationName: true,
            makerName: false,
            modelNumber: false,
            ipAddress: true,
            terminalNumber: false,
            serialNumber: false,
            name: false,
            deviceType: false,
            osType: false,
            osVersion: false,
            modelName: false,
            macAddress: true,
            direction: false,
            directionName: true,
            terminalType: false,
            terminalTypeName :true,
            remarks: true,
            serviceType: false,
            serviceTypeName: false,
            version: false
        })

        const directions = [
            { directionID:'0', directionName:'入場' },
            { directionID:'1', directionName:'出場' },
            { directionID:'2', directionName:'両用' },
        ]
        const directionMap = directions.reduce((map, direction) => {
                return Object.assign(map, { [direction.directionID]: direction.directionName })
            },{})
        const directionDefault = '0'

        const versions = [
            { versionID:'0', versionName:'1.0.0'}
        ]

        const terminalTypes = [
            { terminalTypeID:'0', terminalTypeName:'タブレット'},
            { terminalTypeID:'1', terminalTypeName:'改札機'},
            { terminalTypeID:'2', terminalTypeName:'QRコード（MPM）'},
        ]
        const terminalTypeMap = terminalTypes.reduce((map, terminalType)=>{
            return Object.assign(map, { [terminalType.terminalTypeID]: terminalType.terminalTypeName })
        },{})

        const serviceTypes = [
            { serviceTypeID:'01', serviceTypeName:'鉄道'},
            { serviceTypeID:'02', serviceTypeName:'バス'},
            { serviceTypeID:'03', serviceTypeName:'入出場ゲート'},
            { serviceTypeID:'04', serviceTypeName:'クーポン'},
        ]
        const serviceTypeMap = serviceTypes.reduce((map, serviceType) => {
            return Object.assign(map, { [serviceType.serviceTypeID]: serviceType.serviceTypeName })
        },{})

        const clearValidate = () => {
            form.value.clearValidate()
        }

        // 入力チェック
        const rules = {
            companyID: [
                {
                    required: true,
                    message: '選択必須です',
                    trigger: 'blur'
                }
            ],
            brandID: [
                {
                    required: true,
                    message: '選択必須です',
                    trigger: 'blur'
                }
            ],
            facilityID: [
                {
                    required: true,
                    message: '選択必須です',
                    trigger: 'blur'
                }
            ],
            ipAddress: [
                {
                    // IPv4
                    pattern: /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/,
                    message: 'IPアドレスの形式で入力してください',
                    trigger: 'blur'

                }
            ],
            macAddress: [
                {
                    pattern:/^(?:[0-9a-fA-F]{2}\:){5}[0-9a-fA-F]{2}$/,
                    message: 'MACアドレスの形式（AA:BB:CC:DD:EE:FF）で入力してください',
                    trigger: 'blur'
                }
            ],
            direction: [
                {
                    required: true,
                    message: '選択必須です',
                    trigger: 'blur'
                }
            ],
            version: [
                {
                    required: true,
                    message: '選択必須です',
                    trigger: 'blur'
                }
            ],
            terminalType: [
                {
                    required: true,
                    message: '選択必須です',
                    trigger: 'blur'
                }
            ],
            serviceType: [
                {
                    required: true,
                    message: '選択必須です',
                    trigger: 'blur'
                }
            ]
        }

        // 検索
        const handleSearch = async () => {
            try {
                const res = await http.get('/v1/terminals', { params: { 
                                terminalID: searchCondition.terminalID,
                                companyID: searchCondition.companyID,
                                brandID: searchCondition.brandID,
                                facilityID: searchCondition.facilityID,
                                locationID: searchCondition.locationID,
                                makerName: searchCondition.makerName,
                                modelNumber: searchCondition.modelNumber,
                                ipAddress: searchCondition.ipAddress,
                                terminalNumber: searchCondition.terminalNumber,
                                serialNumber: searchCondition.serialNumber,
                                name: searchCondition.name,
                                deviceType: searchCondition.deviceType,
                                osType: searchCondition.osType,
                                osVersion: searchCondition.osVersion,
                                modelName: searchCondition.modelName,
                                macAddress: searchCondition.macAddress,
                                direction: searchCondition.direction,
                                version: searchCondition.version,
                                terminalType: searchCondition.terminalType,
                                serviceType: searchCondition.serviceType,
                                remarks: searchCondition.remarks,
                                isDeleted: searchCondition.isDeleted ? 1 : 0
                            }})
                terminals.splice(0) // リストをクリア
                res.data.terminals.map((t: any) =>  {
                terminals.push(new Terminal(t.terminalID, t.companyID, t.brandID, t.facilityID, t.locationID, 
                    t.makerName, t.modelNumber, t.ipAddress, t.terminalNumber, t.serialNumber,
                    t.name, t.deviceType, t.osType, t.osVersion, t.modelName,
                    t.macAddress, t.direction, t.version, t.terminalType, t.serviceType, t.remarks , t.isDeleted,
                    t.companyName, t.brandName, t.facilityName, t.locationName,
                    directionMap[t.direction], terminalTypeMap[t.terminalType], serviceTypeMap[t.serviceType]))
                })

                if (terminals.length > 0) {
                ElNotification({ type: 'success', message: `${terminals.length} 件ヒットしました` })
                } else {
                ElNotification({ type: 'error', message: '検索結果がありません' })
                }
            }
            catch (reason: any){
                ElNotification({ type: 'error', message: '処理に失敗しました' })
                throw new Error(`search error: ${reason.response.status} ${reason.response.data.message}`)
            }
        }

        // 新規作成ダイアログ表示
        const showCreateDialog = () => {
            terminal.terminalID = ''
            terminal.companyID = ''
            terminal.brandID = ''
            terminal.facilityID = ''
            terminal.locationID = ''
            terminal.makerName = ''
            terminal.modelNumber = ''
            terminal.ipAddress = ''
            terminal.terminalNumber = ''
            terminal.serialNumber = ''
            terminal.name = ''
            terminal.deviceType = ''
            terminal.osType = ''
            terminal.osVersion = ''
            terminal.modelName = ''
            terminal.macAddress = ''
            terminal.direction = directionDefault
            terminal.version = ''
            terminal.terminalType = ''
            terminal.serviceType = ''
            terminal.remarks = ''
            terminal.isDeleted = false

            isEdit.value = false
            isDeleteVisible.value = false
            isDialogVisible.value = true
        }

        // 新規作成
        const handleCreate = () => {
            // 入力チェック
            form.value.validate(async (valid) => { 
                if (!valid) {
                    // validation error
                    return
                }
                try {
                    const res = await http.post('/v1/terminals', { 
                        terminalID: terminal.terminalID,
                        companyID: terminal.companyID,
                        brandID: terminal.brandID,
                        facilityID: terminal.facilityID,
                        locationID: terminal.locationID != "" ? terminal.locationID : null,
                        makerName: terminal.makerName,
                        modelNumber: terminal.modelNumber,
                        ipAddress: terminal.ipAddress,
                        terminalNumber: terminal.terminalNumber,
                        serialNumber: terminal.serialNumber,
                        name: terminal.name,
                        deviceType: terminal.deviceType,
                        osType: terminal.osType,
                        osVersion: terminal.osVersion,
                        modelName: terminal.modelName,
                        macAddress: terminal.macAddress,
                        direction: terminal.direction,
                        version: terminal.version,
                        terminalType: terminal.terminalType,
                        serviceType: terminal.serviceType,
                        remarks: terminal.remarks,
                        isDeleted: terminal.isDeleted ? 1 : 0,
                        })

                    terminals.push(new Terminal(res.data.terminal.terminalID, terminal.companyID, terminal.brandID, terminal.facilityID, terminal.locationID, 
                        terminal.makerName, terminal.modelNumber, terminal.ipAddress, terminal.terminalNumber, terminal.serialNumber,
                        terminal.name, terminal.deviceType, terminal.osType, terminal.osVersion, terminal.modelName,
                        terminal.macAddress,terminal.direction, terminal.version, terminal.terminalType, terminal.serviceType, terminal.remarks , terminal.isDeleted ? 1 : 0,
                        companies[companies.findIndex((c)=> c.companyID === terminal.companyID)].companyName,
                        brands[brands.findIndex(b => b.brandID === terminal.brandID)].brandName,
                        facilities[facilities.findIndex(f => f.facilityID === terminal.facilityID)].facilityName,
                        terminal.locationID != "" ? locations[locations.findIndex(l => l.locationID === terminal.locationID)].locationName : "",
                        directionMap[terminal.direction], terminalTypeMap[terminal.terminalType], serviceTypeMap[terminal.serviceType]))
                    ElNotification({ type: 'success', message: '正常終了しました' })
                    isDialogVisible.value = false
                }
                catch(reason: any) {
                    ElNotification({ type: 'error', message: '処理に失敗しました' })
                    isDialogVisible.value = false
                    throw new Error(`create terminal error: ${reason.response.status} ${reason.response.data.message}`)
                }
                
            })
        }

        // 更新ダイアログ表示
        const showUpdateDialog = (idx: number, row: Terminal) => {
            terminal.terminalID = row.terminalID
            terminal.companyID = row.companyID
            terminal.brandID = row.brandID
            terminal.facilityID = row.facilityID
            terminal.locationID = row.locationID
            terminal.makerName = row.makerName
            terminal.modelNumber = row.modelNumber
            terminal.ipAddress = row.ipAddress
            terminal.terminalNumber = row.terminalNumber
            terminal.serialNumber = row.serialNumber
            terminal.name = row.name
            terminal.deviceType = row.deviceType
            terminal.osType = row.osType
            terminal.osVersion = row.osVersion
            terminal.modelName = row.modelName
            terminal.macAddress = row.macAddress
            terminal.direction = row.direction
            terminal.version = row.version
            terminal.terminalType = row.terminalType
            terminal.serviceType = row.serviceType
            terminal.remarks = row.remarks
            terminal.isDeleted = row.isDeleted

            isEdit.value = true
            isDeleteVisible.value = row.isDeleted
            isDialogVisible.value = true
        }

        // 更新
        const handleUpdate = () => {
            // 入力チェック
            form.value.validate(async (valid) => {
                if (!valid) {
                    // validation error
                    return
                }

                try {
                    const res = await http.patch(`/v1/terminals/${terminal.terminalID}`, { 
                            terminalID: terminal.terminalID,
                            companyID: terminal.companyID,
                            brandID: terminal.brandID,
                            facilityID: terminal.facilityID,
                            locationID: terminal.locationID != "" ? terminal.locationID : null,
                            makerName: terminal.makerName,
                            modelNumber: terminal.modelNumber,
                            ipAddress: terminal.ipAddress,
                            terminalNumber: terminal.terminalNumber,
                            serialNumber: terminal.serialNumber,
                            name: terminal.name,
                            deviceType: terminal.deviceType,
                            osType: terminal.osType,
                            osVersion: terminal.osVersion,
                            modelName: terminal.modelName,
                            macAddress: terminal.macAddress,
                            direction: terminal.direction,
                            version: terminal.version,
                            terminalType: terminal.terminalType,
                            serviceType: terminal.serviceType,
                            remarks: terminal.remarks,
                            isDeleted: terminal.isDeleted ? 1 : 0
                        })

                    const t = terminals[terminals.findIndex(i => i.terminalID === terminal.terminalID)]
                    t.companyID = terminal.companyID
                    t.brandID = terminal.brandID
                    t.facilityID = terminal.facilityID
                    t.locationID = terminal.locationID
                    t.makerName = terminal.makerName
                    t.modelNumber = terminal.modelNumber
                    t.ipAddress = terminal.ipAddress
                    t.terminalNumber = terminal.terminalNumber
                    t.serialNumber = terminal.serialNumber
                    t.name = terminal.name
                    t.deviceType = terminal.deviceType
                    t.osType = terminal.osType
                    t.osVersion = terminal.osVersion
                    t.modelName = terminal.modelName
                    t.macAddress = terminal.macAddress
                    t.direction = terminal.direction
                    t.version = terminal.version
                    t.terminalType = terminal.terminalType
                    t.serviceType = terminal.serviceType
                    t.remarks = terminal.remarks
                    t.isDeleted = terminal.isDeleted

                    t.companyName = companies[companies.findIndex(i => i.companyID === t.companyID)].companyName
                    t.brandName = brands[brands.findIndex(i => i.brandID === t.brandID)].brandName
                    t.facilityName = facilities[facilities.findIndex(i => i.facilityID === t.facilityID)].facilityName
                    t.locationName = terminal.locationID != "" ? locations[locations.findIndex(i => i.locationID === t.locationID)].locationName : ""

                    t.directionName = directionMap[terminal.direction]
                    t.terminalTypeName = terminalTypeMap[terminal.terminalType]
                    t.serviceTypeName = serviceTypeMap[terminal.serviceType]

                    ElNotification({ type: 'success', message: '正常終了しました' })
                    isDialogVisible.value = false
                }
                catch(reason: any){
                    ElNotification({ type: 'error', message: '処理に失敗しました' })
                    isDialogVisible.value = false
                    throw new Error(`update terminal error: ${reason.response.status} ${reason.response.data.message}`)
                }
                
                
            })
        }

        // 削除確認ダイアログ表示
        const showDeleteDialog = (idx: number, row: Terminal) => {
            terminal.terminalID = row.terminalID
            terminal.companyName = row.companyName
            terminal.brandName = row.brandName
            terminal.facilityName = row.facilityName
            terminal.locationName = row.locationName
            terminal.remarks = row.remarks

            isConfirmVisible.value = true
        }

        // 削除
        const handleDelete = async () => {
            try {
                const res = await http.delete(`/v1/terminals/${terminal.terminalID}`)
                terminals.splice(terminals.findIndex(i => i.terminalID === terminal.terminalID), 1)
                ElNotification({ type: 'success', message: '正常終了しました' })
                isConfirmVisible.value = false
            }
            catch (reason: any){
                ElNotification({ type: 'error', message: '処理に失敗しました' })
                isConfirmVisible.value = false
                throw new Error(`delete terminal error: ${reason.response.status} ${reason.response.data.message}`)
            }
        }

        const getCompanies = async () => {
            try {
                const res = await http.get('/v1/companies')
                res.data.companies.map((c: any) => {
                    companies.push(new Company(c.companyID, c.companyName, c.companyAlias, c.businessCode, c.isDeleted))
                })
            }
            catch (reason){
                ElNotification({ type: 'error', message: '加盟店マスタ取得処理に失敗しました' })
            }
        }

        const getBrands = async () => {
            try {
                const res = await http.get('/v1/brands')
                // 初期化
                brands.splice(0)
                res.data.brands.map((b: any) => {
                    brands.push(new Brand(b.brandID, b.brandName, b.brandCode, b.companyID, b.timeZone, b.isDeleted))
                })
            }
            catch (reason){
                ElNotification({ type: 'error', message: 'ブランドマスタ取得処理に失敗しました' })
            }
        }

        const filterBrands = (target = 'Entry') => {
            // 対象リストを選択
            const arr = target === 'Entry' ? filteredBrands : filteredBrandsSearch
            const search = target === 'Entry' ? terminal : searchCondition
            // 初期化
            arr.splice(0)
            arr.push(...brands.filter((brand) => { return brand.companyID === search.companyID}))
        }

        const getFacilities = async () => {
            try {
                const res = await http.get('/v1/facilities')
                // 初期化
                facilities.splice(0)
                res.data.facilities.map((f: any) =>  {
                    facilities.push(new Facility(f.facilityID, f.facilityName, f.facilityCode, f.brandID,
                        f.zip, f.country, f.state, f.city, f.address, f.phone, f.longitude, f.latitude, f.range, f.picture, f.isDeleted))
                })
            }
            catch (reason){
                ElNotification({ type: 'error', message: '施設マスタ取得処理に失敗しました' })
            }
        }

        const filterFacilities = (target = 'Entry') => {
            // 対象リストを選択
            const arr = target === 'Entry' ? filteredFacilities : filteredFacilitiesSearch
            const search = target === 'Entry' ? terminal : searchCondition
            // 初期化
            arr.splice(0)
            arr.push(...facilities.filter((facility) => { return facility.brandID === search.brandID}))
        }

        const getLocations = async () => {
            try {
                const res = await http.get('/v1/locations')
                // 初期化
                locations.splice(0)
                res.data.locations.map((l: any) =>  {
                    locations.push(new Location(l.locationID, l.locationName, l.locationCode, l.facilityID, l.isDeleted))
                })
            }
            catch (reason){
                ElNotification({ type: 'error', message: 'ロケーションマスタ取得処理に失敗しました' })
            }
        }

        const filterLocations = (target = 'Entry') => {
            // 対象リストを選択
            const arr = target === 'Entry' ? filteredLocations : filteredLocationsSearch
            const search = target === 'Entry' ? terminal : searchCondition
            // 初期化
            arr.splice(0)
            arr.push(...locations.filter((location) => { return location.facilityID === search.facilityID}))
        }

        onMounted(()=>{
            getCompanies()
            getBrands()
            getFacilities()
            getLocations()
            handleSearch()
        })

        watch(()=>terminal.companyID,(companyID,prevID)=>{
            // 選択済み項目を初期化
            // HACK: 編集時にも初期化されてしまうので、前の値が空ならダイアログ立ち上げ時とみなし初期化しない
            // isDialogVisibleをwatchしてダイアログを閉じたときにIDを空にしておく。もっと良い方法はないか？
            if(prevID !== ""){
                terminal.brandID = ""
            }
            filterBrands()
        })

        watch(()=>terminal.brandID, (brandID,prevID)=>{
            // 選択済み項目を初期化
            // HACK: 編集時にも初期化されてしまうので、前の値が空ならダイアログ立ち上げ時とみなし初期化しない
            // isDialogVisibleをwatchしてダイアログを閉じたときにIDを空にしておく。もっと良い方法はないか？
            if(prevID !== ""){
                terminal.facilityID = ""
            }
            filterFacilities()
        })

        watch(()=>terminal.facilityID, (facilityID,prevID)=>{
            // 選択済み項目を初期化
            // HACK: 編集時にも初期化されてしまうので、前の値が空ならダイアログ立ち上げ時とみなし初期化しない
            // isDialogVisibleをwatchしてダイアログを閉じたときにIDを空にしておく。もっと良い方法はないか？
            if(prevID !== ""){
                terminal.locationID = ""
            }
            filterLocations()
        })

        watch(()=>isDialogVisible.value,(visible,prev)=>{
            if(!visible){
                // HACK: 各IDが編集時にも初期化されてしまうので、前の値が空ならダイアログ立ち上げ時とみなし初期化しない
                // isDialogVisibleをwatchしてダイアログを閉じたときにIDを空にしておく。もっと良い方法はないか？
                terminal.companyID = ""
                terminal.brandID = ""
                terminal.facilityID = ""
                terminal.locationID = ""
            }
        })

        watch(()=> searchCondition.companyID, (companyID,prevID)=>{
            searchCondition.brandID = ""
            filterBrands('Search')
        })

        watch(()=> searchCondition.brandID, (brandID,prevID)=>{
            searchCondition.facilityID = ""
            filterFacilities('Search')
        })

        watch(()=> searchCondition.facilityID, (facilityID,prevID)=>{
            searchCondition.locationID = ""
            filterLocations('Search')
        })

        return { searchCondition, terminals, terminal, 
            companies, brands, facilities, locations,
            filteredBrands, filteredFacilities, filteredLocations,
            filteredBrandsSearch, filteredFacilitiesSearch, filteredLocationsSearch,
            directions, versions, terminalTypes, serviceTypes,
            form, rules, clearValidate, filter,
            showCreateDialog, showUpdateDialog, showDeleteDialog,
            handleCreate, handleUpdate, handleSearch, handleDelete,
            isEdit, isDialogVisible, isConfirmVisible, isSearchVisible, isDeleteVisible,
            dispColumns }
    },
})
