export default class Terminal {
    terminalID: string
    companyID: string
    brandID: string
    facilityID: string
    locationID: string
    makerName: string
    modelNumber: string
    ipAddress: string
    terminalNumber: string
    serialNumber: string
    name: string
    deviceType: string
    osType: string
    osVersion: string
    modelName: string
    macAddress: string
    direction: string
    version: string
    terminalType: string
    serviceType: string
    remarks: string
    isDeleted: boolean
    companyName: string
    brandName: string
    facilityName: string
    locationName: string
    directionName: string
    terminalTypeName: string
    serviceTypeName: string

    constructor(terminalID: string, companyID: string, brandID: string, facilityID: string, locationID: string | null,
        makerName: string | null,  modelNumber: string | null, ipAddress: string | null, terminalNumber: string | null, serialNumber: string | null,
        name: string | null, deviceType: string | null, osType: string | null, osVersion: string | null, modelName: string | null,
        macAddress: string | null, direction: string, version: string, terminalType: string, serviceType: string, remarks: string, isDeleted: number,
        companyName: string = '', brandName: string = '', facilityName: string = '', locationName: string = '',
        directionName: string = '', terminalTypeName: string = '', serviceTypeName: string = '') {
        this.terminalID = terminalID
        this.companyID = companyID
        this.brandID = brandID
        this.facilityID = facilityID
        this.locationID = locationID ?? ""
        this.makerName = makerName ?? ""
        this.modelNumber = modelNumber ?? ""
        this.ipAddress = ipAddress ?? ""
        this.terminalNumber = terminalNumber ?? ""
        this.serialNumber = serialNumber ?? ""
        this.name = name ?? ""
        this.deviceType = deviceType ?? ""
        this.osType = osType ?? ""
        this.osVersion = osVersion ?? ""
        this.modelName = modelName ?? ""
        this.macAddress = macAddress ?? ""
        this.direction = direction
        this.version = version
        this.terminalType = terminalType
        this.serviceType = serviceType
        this.remarks = remarks
        this.isDeleted = isDeleted === 1
        this.companyName = companyName
        this.brandName = brandName
        this.facilityName = facilityName
        this.locationName = locationName
        this.directionName = directionName
        this.terminalTypeName = terminalTypeName
        this.serviceTypeName = serviceTypeName
    }
}