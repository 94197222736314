
import { defineComponent, reactive, ref, onMounted } from 'vue'
import Role from '@/models/role'
import User from '@/models/user'
import http from "@/modules/httpclient"
import { ElNotification } from 'element-plus'

//type MessageType = 'success' | 'error'

export default defineComponent({
  props:{
    loginUser:User
  },
  setup() {
    const searchCondition = reactive<Role>(new Role('', '', 99, '',[], 0))
    const roles = reactive<Role[]>([])
    const role = reactive<Role>(new Role('', '', 99, '',[], 0))
    const authorityLevelList = reactive([
      { levelID:10, levelName: 'システム管理'},
      { levelID:20, levelName: '運用管理'},
      { levelID:30, levelName: '加盟店管理(オーナー)'},
      { levelID:40, levelName: '管理レベル1(ブランド)'},
      { levelID:50, levelName: '管理レベル2(店舗)'},
      { levelID:60, levelName: '利用者'}
    ])

    const functionList = ref([
      { functionID: 'user', functionName: 'ユーザー管理' },
      { functionID: 'role', functionName: '権限管理'},
      { functionID: 'company', functionName: '加盟店メンテナンス' },
      { functionID: 'brand', functionName: 'ブランドメンテナンス' },
      { functionID: 'facility', functionName: '施設メンテナンス' },
      { functionID: 'location', functionName: 'ロケーションメンテナンス' },
      { functionID: 'terminal', functionName: '端末メンテナンス' }
    ])
    // const connector = ref([])
    
    const isEdit = ref(false)
    const isDeleteVisible = ref(false)
    const isDialogVisible = ref(false)
    const isConfirmVisible = ref(false)
    const isSearchVisible = ref(false)
    const form = ref()
    const filter = ref('')

    const dispColumns = reactive({
      roleCode:true,
      roleName:true,
      authorityLevel:true,
      remarks:true
    })

    const clearValidate = () => {
        form.value.clearValidate()
    }

    // 入力チェック
    const rules = {
      roleCode: [
        {
          required: true,
          message: '必須入力です',
          trigger: 'blur'
        },
        {
          validator: async (rule, value, callback) => {
            // facility_id と合わせてユニークにする
            if(isEdit.value){
                callback()
            }

            try{
                const res = await http.get('/v1/roles',{
                    params:{
                        roleCode:value,
                        isDeleted:1 // 削除済みも含む
                    }
                })
                if(res.data.roles.length > 0){
                    callback(new Error('すでに使用されているコードです'))
                }
                else{
                    // OK
                    callback()
                }
            }
            catch (reason){
                ElNotification({ type: 'error', message: '予期しないエラーが発生しました' })
                callback(new Error('予期しないエラーが発生しました'))
            }
          },
          trigger:'blur'
        }
      ],
      roleName: [
        {
          required: true,
          message: '必須入力です',
          trigger: 'blur'
        }
      ],
      authorityLevel: [
        {
          required: true,
          message: '選択必須です',
          trigger: 'change'
        }
      ],
      functions:[
        {
          validator: (rule, value, callback) => {
            if(value.length === 0){
              callback(new Error('一つ以上選択してください'))
            }
            callback()
          },
          trigger: 'blur'
        }
      ]
    }


    // 検索
    const handleSearch = async () => {
      try {
        const res = await http.get('/v1/rolesWithFunction', { params: {
          roleCode: searchCondition.roleCode,
          roleName: searchCondition.roleName,
          isDeleted: searchCondition.isDeleted ? 1 : 0
        }})
        roles.splice(0)
        res.data.roles.map((r: any) => {
          roles.push(new Role(r.roleCode, r.roleName, r.authorityLevel, r.remarks, r.functions, r.isDeleted))
        })
        if (roles.length > 0) {
          ElNotification({ type: 'success', message: `${roles.length} 件ヒットしました` })
        } else {
            ElNotification({ type: 'error', message: '検索結果がありません' })
        }
      }
      catch (reason) {
        ElNotification({ type: 'error', message: '処理に失敗しました' })
        throw new Error(`search error: ${reason.response.status} ${reason.response.data.message}`)
      }
    }

    // 新規作成ダイアログ表示
    const showCreateDialog = () => {
      role.roleCode = ''
      role.roleName = ''
      role.authorityLevel = authorityLevelList[authorityLevelList.length -1].levelID
      role.remarks = ''
      role.functions = []
      role.isDeleted = false

      isEdit.value = false
      isDeleteVisible.value = false
      isDialogVisible.value = true
    }

    // 新規作成
    const handleCreate = () => {
      
      // 入力チェック
      form.value.validate(async (valid) => { 
        if (!valid) {
          // validation error
          return
        }
        try{
          const res = await http.post('/v1/roles', { 
            roleCode: role.roleCode,
            roleName: role.roleName,
            authorityLevel: role.authorityLevel,
            remarks: role.remarks,
            functions: role.functions,
            isDeleted: role.isDeleted ? 1 : 0,
          })
          roles.push(new Role(role.roleCode, role.roleName, role.authorityLevel, role.remarks, role.functions, role.isDeleted ? 1 : 0))
          ElNotification({ type: 'success', message: '正常終了しました' })
          isDialogVisible.value = false
        }
        catch (reason) {
          ElNotification({ type: 'error', message: '処理に失敗しました' })
          isDialogVisible.value = false
          throw new Error(`create role error: ${reason.response.status} ${reason.response.data.message}`)
        }
      })

    }

    // 更新ダイアログ表示
    const showUpdateDialog = (idx: number, row: Role) => {
      role.roleCode = row.roleCode
      role.roleName = row.roleName
      role.authorityLevel = row.authorityLevel
      role.remarks = row.remarks
      role.functions = row.functions
      role.isDeleted = row.isDeleted

      isEdit.value = true
      isDeleteVisible.value = row.isDeleted
      isDialogVisible.value = true
    }

    // 更新
    const handleUpdate = () => {

      // 入力チェック
      form.value.validate(async (valid) => {
        if (!valid) {
          // validation error
          return
        }

        try {
          const res = await http.patch(`/v1/roles/${role.roleCode}`, { 
            roleCode: role.roleCode,
            roleName: role.roleName,
            authorityLevel: role.authorityLevel,
            remarks: role.remarks,
            functions: role.functions,
            isDeleted: role.isDeleted ? 1 : 0,
          })

          const r = roles[roles.findIndex(i => i.roleCode === role.roleCode)]
          r.roleName = role.roleName
          r.authorityLevel = role.authorityLevel
          r.remarks = role.remarks
          r.isDeleted = role.isDeleted
          r.functions = role.functions

          ElNotification({ type: 'success', message: '正常終了しました' })
          isDialogVisible.value = false
        }
        catch (reason){
          ElNotification({ type: 'error', message: '処理に失敗しました' })
          isDialogVisible.value = false
          throw new Error(`update role error: ${reason.response.status} ${reason.response.data.message}`)
        }
      })
    }

    // 削除確認ダイアログ表示
    const showDeleteDialog = (idx: number, row: Role) => {
      role.roleCode = row.roleCode
      role.roleName = row.roleName
      role.authorityLevel = row.authorityLevel

      isConfirmVisible.value = true
    }

    // 削除
    const handleDelete = async () => {
      try {
        const res = await http.delete(`/v1/roles/${role.roleCode}`)
        roles.splice(roles.findIndex(i => i.roleCode === role.roleCode), 1)
        ElNotification({ type: 'success', message: '正常終了しました' })
        isConfirmVisible.value = false
      }
      catch (reason){
        ElNotification({ type: 'error', message: '処理に失敗しました' })
        isConfirmVisible.value = false
        throw new Error(`delete role error: ${reason.response.status} ${reason.response.data.message}`)
      }
      
    }

    onMounted(() => {
      handleSearch()
    })

    return { searchCondition, roles, role, 
      authorityLevelList, functionList,
      form, rules, clearValidate, filter,
      showCreateDialog, showUpdateDialog, showDeleteDialog,
      handleCreate, handleUpdate, handleSearch, handleDelete,
      isEdit, isDialogVisible, isConfirmVisible, isSearchVisible, isDeleteVisible,
      dispColumns }
  }

})

